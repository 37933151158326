import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import classnames from 'classnames';
import { ServerVarsContextConsumer } from '../../sharedComponents/ServerVarsContext';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import styles from './styles/StorefrontCreatorsUrls.scss';

const linksClassName = classnames(styles.links, 'rowFlex');
const title = (
    <FormattedMessage id="StorefrontCreatorsUrls.title" defaultMessage="Featured Creators" />
);
function StorefrontCreatorsUrls({ featuredCreatorsUrls }) {
    const creatorsUrls = featuredCreatorsUrls?.creatorsUrls ?? [];
    if (creatorsUrls && creatorsUrls.length) {
        return (
            <ServerVarsContextConsumer>
                {({ isMobile }) => (
                    <HeadingLevel>
                        {Heading => (
                            <>
                                {isMobile ? (
                                    <>
                                        <Heading>{title}</Heading>
                                        <div className={styles.innerWrapper}>
                                            <div className={styles.itemContainer}>
                                                {creatorsUrls.map(({ displayName, linkData }) => (
                                                    <div className={styles.item} key={displayName}>
                                                        <SeoLink
                                                            className={styles.noUnderline}
                                                            linkData={linkData}
                                                        >
                                                            {displayName}
                                                        </SeoLink>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <BarHeader title={title} />
                                        <div className={linksClassName}>
                                            {creatorsUrls.map(({ displayName, linkData }) => (
                                                <SeoLink
                                                    key={displayName}
                                                    linkData={linkData}
                                                    className={classnames(
                                                        styles.link,
                                                        'colXs12',
                                                        'colMd4'
                                                    )}
                                                >
                                                    {displayName}
                                                </SeoLink>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </HeadingLevel>
                )}
            </ServerVarsContextConsumer>
        );
    }
    return null;
}

StorefrontCreatorsUrls.propTypes = {
    featuredCreatorsUrls: PropTypes.shape({
        creatorsUrls: PropTypes.array,
    }),
};

export default createFragmentContainer(StorefrontCreatorsUrls, {
    featuredCreatorsUrls: graphql`
        fragment StorefrontCreatorsUrls_featuredCreatorsUrls on DealerStorefrontQueryConnection {
            creatorsUrls {
                displayName
                linkData {
                    ...SeoLink_linkData
                }
            }
        }
    `,
});
