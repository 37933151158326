import PropTypes from 'prop-types';

import styles from './styles.scss';
import MediaItem from './MediaItem';

function MediaCarouselItem({ isActive, element, useSrcSet }) {
    return (
        <div className={styles.carouselItem} style={{ opacity: isActive ? 1 : 0.3 }}>
            <MediaItem {...element} useSrcSet={useSrcSet} />
        </div>
    );
}

MediaCarouselItem.propTypes = {
    isActive: PropTypes.bool.isRequired,
    element: PropTypes.shape({
        url: PropTypes.string,
        path: PropTypes.string,
        caption: PropTypes.string,
        mediaType: PropTypes.oneOf(['PHOTO', 'VIDEO']).isRequired,
    }).isRequired,
    useSrcSet: PropTypes.bool.isRequired,
};

export default MediaCarouselItem;
