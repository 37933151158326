/**
 * @generated SignedSource<<a7d432f03a6e28fc8a94cf9b036f53a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontLayout_viewer$data = {
  readonly seller: {
    readonly " $fragmentSpreads": FragmentRefs<"StorefrontHead_seller" | "StorefrontHome_seller">;
  } | null;
  readonly storefrontData: {
    readonly " $fragmentSpreads": FragmentRefs<"StorefrontHome_storefrontData">;
  } | null;
  readonly user?: {
    readonly " $fragmentSpreads": FragmentRefs<"StorefrontHead_user" | "StorefrontHome_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontHead_viewer" | "StorefrontHome_viewer">;
  readonly " $fragmentType": "StorefrontLayout_viewer";
};
export type StorefrontLayout_viewer$key = {
  readonly " $data"?: StorefrontLayout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontLayout_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "sellerId",
  "variableName": "sellerId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "sellerId"
    },
    {
      "kind": "RootArgument",
      "name": "urlLabel"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontLayout_viewer",
  "selections": [
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StorefrontHead_user"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StorefrontHome_user"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StorefrontHead_seller"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StorefrontHome_seller"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "storefrontData",
      "args": [
        {
          "kind": "Variable",
          "name": "dealer",
          "variableName": "urlLabel"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 12
        },
        (v0/*: any*/)
      ],
      "concreteType": "DealerStorefrontQueryConnection",
      "kind": "LinkedField",
      "name": "dealerStorefront",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StorefrontHome_storefrontData"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontHead_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontHome_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "e5b5fc6f1fedbf53704afea8c1db688c";

export default node;
