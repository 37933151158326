'use strict';

const createChainableTypeChecker = require('../createChainableTypeChecker');

/**
 * Currently React's instanceOf PropTypes checker does not work with ES2015 customized instanceof checks. This is a
 * custom propType validator that will allow a component to check if a prop is an instance of a particular enum type.
 *
 * Example:
 * const myEnum = createEnum('one', 'two');
 * const enumInstance = myEnum.one();
 *
 * class MyReactComponent { ... }
 * MyReactComponent.propTypes = {
 *     enumProp: instanceOfEnum(myEnum).isRequired,
 *     moreEnums: PropTypes.arrayOf(instanceOfEnum(myEnum)).isRequired
 * }
 *
 * @param  {Enum} enumType The enum "class" to check for.
 * @return {Function}          A React prop types validation function for that enum.
 */
function instanceOfEnum(enumType) {
    // Make this prop type requireable with `isRequired`
    return createChainableTypeChecker((props, propName, componentName, location, propFullName) => {
        // Prop is guaranteed to be defined at this point so no need to check for it.
        if (!(props[propName] instanceof enumType)) {
            // React requires custom validators to `return` an error and not `throw`
            return new Error(
                `Must pass an enum instance of correct type to ${location} \`${propFullName}\` in \`${componentName}\``
            );
        } else {
            return null;
        }
    });
}

module.exports = instanceOfEnum;
