import { useId, FC, useRef } from 'react';
import classNames from 'classnames';

import styles from './main.scss';

type StarProps = {
    percentFilled?: number;
    size?: 'x-small' | 'medium' | 'small' | 'smaller' | 'large'; //TODO: add new sizes as needed
    showBorder?: boolean;
};

const POLYGON_POINTS =
    '125 207.3 47.7 250 62.5 159.5 0 95.5 86.4 82.3 125 0 163.6 82.3 250 95.5 187.5 159.5 202.3 250';
let gradientIdCounter = 0;

export const Star: FC<StarProps> = ({ percentFilled = 0, size = 'large', showBorder = false }) => {
    // keep module scope as an id fallback. this
    // allows the continued possibility of SSR/CSR mismatch but prevents gradient id
    // collision  when multiple Stars are rendered with different props
    const fallbackId = useRef(gradientIdCounter++);
    const gradientId = `starGradient${useId() ?? fallbackId.current}`;

    const isFullStar = percentFilled >= 100;
    const isEmptyStar = percentFilled <= 0;

    const svgClass = classNames(styles.svg, {
        [styles.medium]: size === 'medium',
        [styles.small]: size === 'small',
        [styles.smaller]: size === 'smaller',
        [styles.large]: size === 'large',
        [styles.xSmall]: size === 'x-small',
        [styles.bordered]: showBorder,
        [styles.fullStar]: isFullStar,
        [styles.emptyStar]: isEmptyStar,
    });

    if (isFullStar || isEmptyStar) {
        // full/empty stars have no need for the linear gradient
        return (
            <svg className={svgClass} viewBox="0 0 250 250" version="1.1">
                <polygon points={POLYGON_POINTS} />
            </svg>
        );
    }
    return (
        <svg className={svgClass} viewBox="0 0 250 250" version="1.1">
            <defs>
                <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" className={styles.gradientLeft} />
                    <stop offset={`${percentFilled}%`} className={styles.gradientLeft} />
                    <stop offset={`${percentFilled}%`} className={styles.gradientRight} />
                    <stop offset="100%" className={styles.gradientRight} />
                </linearGradient>
            </defs>
            <polygon points={POLYGON_POINTS} fill={`url(#${gradientId})`} />
        </svg>
    );
};
