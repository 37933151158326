/**
 * Calculates the angle for a given cartesian coordinate.
 * @method getPointAngle
 * @param  {Number} x - Point position on the X axis
 * @param  {Number} y - Point position on the Y axis
 * @return {Number} - Angle in degrees
 */
export function getPointAngle(x, y) {
    let angle = Math.atan2(y, x) * (180 / Math.PI);

    if (angle < 0) {
        angle += 360;
    }
    return angle;
}

/**
 * Calculates the quadrant for a give angle.
 * @method getQuadrant
 * @param  {Number} angle - Angle in degrees from 0 to 360
 * @return {Number} - Quadrant from 0 to 3
 */
export function getQuadrant(angle) {
    return Math.floor((angle / 90) % 4);
}
