import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import classNames from 'classnames';
import get from 'lodash.get';
import { Button } from 'dibs-elements/exports/Button';
import StorefrontProductGrid from './grid/StorefrontProductGrid';
import StorefrontSectionHeader from './StorefrontSectionHeader';
import styles from './styles/StorefrontFeaturedSection.scss';

const productGridClasses = {
    gridClass: `${styles.content}-productGrid rowFlex`,
    gridItemClass: `colXl3 colLg4 colXs6 ${styles.gridItem}`,
};

function StorefrontFeaturedSection({ seller, isPreview, isTrade, user, viewer, products }) {
    const urlLabel = get(seller, 'sellerPreferences.urlLabel');
    const shopUrl = `/dealers/${urlLabel}/shop/`;

    const className = classNames(styles.section, {
        [styles.isPreview]: isPreview,
    });

    return (
        <section className={className}>
            <StorefrontSectionHeader
                header={
                    <FormattedMessage
                        id="StorefrontFeaturedSection.header"
                        defaultMessage="Featured Pieces"
                    />
                }
            />

            <div className={styles.content}>
                {/* Product Grid */}
                <StorefrontProductGrid
                    classes={productGridClasses}
                    isTrade={isTrade}
                    user={user}
                    viewer={viewer}
                    products={products}
                />
            </div>

            <div className={styles.footer}>
                <Button type="secondary" dataTn="storefront-home-featured-view-all" href={shopUrl}>
                    <FormattedMessage
                        id="StorefrontFeaturedSection.buttonCta"
                        defaultMessage="View All"
                    />
                </Button>
            </div>
        </section>
    );
}

StorefrontFeaturedSection.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    user: PropTypes.object,
    viewer: PropTypes.object,
    seller: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    isTrade: PropTypes.bool,
};

export default createFragmentContainer(StorefrontFeaturedSection, {
    viewer: graphql`
        fragment StorefrontFeaturedSection_viewer on Viewer {
            ...StorefrontProductGrid_viewer
        }
    `,
    user: graphql`
        fragment StorefrontFeaturedSection_user on User {
            ...StorefrontProductGrid_user
        }
    `,
    seller: graphql`
        fragment StorefrontFeaturedSection_seller on Seller {
            sellerPreferences {
                urlLabel
            }
        }
    `,
    products: graphql`
        fragment StorefrontFeaturedSection_products on DealerStorefrontQueryConnection {
            ...StorefrontProductGrid_products
        }
    `,
});
