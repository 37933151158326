import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import contactDealerLauncher from 'dibs-buyer-layout/exports/contactDealerLauncher';
import { getRelayEnvironment } from '../../utils/relayModernEnvironment';

function StorefrontContactButton(props) {
    const environment = getRelayEnvironment();
    const onClick = () => {
        const { seller, isPreview } = props;
        const sellerId = seller ? seller.serviceId : null;
        if (!isPreview && sellerId) {
            contactDealerLauncher({
                environment,
                sellerId,
                launchOrigin: 'in dealer storefront - button',
            });
        }
    };

    return (
        <div
            className={props.className}
            data-tn="storefront-head-contact-button"
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onClick();
                }
            }}
            role="button"
            tabIndex={0}
            onClick={onClick}
        >
            <FormattedMessage id="StorefrontContactButton.cta" defaultMessage="Message" />
        </div>
    );
}

StorefrontContactButton.propTypes = {
    className: PropTypes.string,
    isPreview: PropTypes.bool.isRequired,
    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontContactButton, {
    seller: graphql`
        fragment StorefrontContactButton_seller on Seller {
            serviceId
        }
    `,
});
