// Flow types automatically removed

import { Component } from 'react';
import classNames from 'classnames';
import root from './helpers/windowCheck'; // Utils

import {
    getElementHeight,
    getElementPosition,
    didSettingsChange,
    getInitialSettings,
    getWindowWidth,
    getWindowScrollPosition,
} from './helpers/utils'; // Types

// Component
import styles from './styles.scss';
import ProfileCoverLayout from './ProfileCoverLayout';

class ProfileCover extends Component {
    constructor(props) {
        super(props);
        this.settings = getInitialSettings(props);
        this.state = {
            isSticky: false,
            size: this.settings.fullStateSize,
        };
        this.wrapperElement = null; // bind methods to instance

        this._updateSettingsBound = this._updateSettings.bind(this);
        this._updateProfileStateBound = this._updateProfileState.bind(this);
    }

    componentDidMount() {
        this._updateSettings(); // Add event listeners to 'scroll' and 'resize' events

        if (!this.settings.disableSticky) {
            root.addEventListener('scroll', this._updateProfileStateBound);
            root.addEventListener('resize', this._updateProfileStateBound);
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (didSettingsChange(this.props, props)) {
            root.removeEventListener('scroll', this._updateProfileStateBound);
            root.removeEventListener('resize', this._updateProfileStateBound);
            this.settings = getInitialSettings(props);

            if (!this.settings.disableSticky) {
                root.addEventListener('scroll', this._updateProfileStateBound);
                root.addEventListener('resize', this._updateProfileStateBound);
            }

            this.setState(
                {
                    isSticky: false,
                    size: this.settings.fullStateSize,
                },
                this._updateSettingsBound
            );
        }
    }

    componentWillUnmount() {
        // Remove event listeners (cleanup)
        root.removeEventListener('scroll', this._updateProfileStateBound);
        root.removeEventListener('resize', this._updateProfileStateBound);
    }

    _updateSettings() {
        const wrapperElement = this.wrapperElement;

        if (wrapperElement) {
            const fullElHeight = getElementHeight(
                wrapperElement.getElementsByClassName(styles.cover)[0]
            );
            wrapperElement.style.height = `${fullElHeight}px`;
            this.settings.stickyHeightThreshold = getElementPosition(
                wrapperElement.getElementsByClassName(styles.bottom)[0]
            ); // update the profile state

            this._updateProfileState();
        }
    }

    _updateProfileState() {
        if (this.settings.disableSticky) {
            return;
        }

        const winWidth = getWindowWidth();
        const winScrollY = getWindowScrollPosition();
        const { fullStateSize, stickyStateSize, stickyWidthThreshold, stickyHeightThreshold } =
            this.settings;
        const wasSticky = this.state.isSticky;
        const isSticky = winWidth > stickyWidthThreshold && winScrollY > stickyHeightThreshold;

        if (!isSticky) {
            // Sticky => Full
            if (wasSticky) {
                const callback = this.props.onUnStick ? this.props.onUnStick : void 0;
                this.setState(
                    {
                        isSticky: false,
                        size: fullStateSize,
                    },
                    callback
                );
            }
        } else {
            let size = fullStateSize + stickyHeightThreshold - winScrollY;

            if (size < stickyStateSize) {
                size = stickyStateSize;
            } else if (size > fullStateSize) {
                size = fullStateSize;
            } // Sticky / Full => Sticky

            if (!wasSticky || size !== this.state.size) {
                const callback = !wasSticky && this.props.onStick ? this.props.onStick : void 0;
                this.setState(
                    {
                        isSticky: true,
                        size,
                    },
                    callback
                );
            }
        }
    }

    _getLayoutProps() {
        const classes = this.props.additionalClasses || {};
        const isSticky = !this.settings.disableSticky && this.state.isSticky;
        return {
            width: this.state.size,
            coverPhoto: this.props.coverPhoto,
            className: classNames(styles.cover, classes.cover, {
                [classNames(styles.sticky, classes.sticky)]: isSticky,
                [classNames(styles.noPicture, classes.noPicture)]: !this.props.PictureRenderer,
            }),
            components: {
                TopRenderer: this.props.TopRenderer,
                TabsRenderer: this.props.TabsRenderer,
                SocialRenderer: this.props.SocialRenderer,
                PictureRenderer: this.props.PictureRenderer,
                DetailsRenderer: this.props.DetailsRenderer,
            },
        };
    }

    render() {
        return (
            <div
                className={styles.wrapper}
                ref={el => {
                    this.wrapperElement = el;
                }}
            >
                <ProfileCoverLayout {...this._getLayoutProps()} />
            </div>
        );
    }
}

ProfileCover.defaultProps = {
    fullStateSize: 160,
    stickyStateSize: 60,
    stickyWidthThreshold: 1024,
};
export default ProfileCover;
