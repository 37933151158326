/**
 * @generated SignedSource<<2c050e81fa39e1b70b6f7900f6ce70c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontProductTile_product$data = {
  readonly ecommerceTrackingParams: any | null;
  readonly photos: ReadonlyArray<{
    readonly versions: ReadonlyArray<{
      readonly webPath: string | null;
    } | null> | null;
  } | null> | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_item" | "ItemFolderWrapper_item" | "ItemHeartWrapper_item" | "ItemTile_item">;
  readonly " $fragmentType": "StorefrontProductTile_product";
};
export type StorefrontProductTile_product$key = {
  readonly " $data"?: StorefrontProductTile_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductTile_product">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "loadPortfolioData",
    "variableName": "hasUserId"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontProductTile_product",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ItemFolderWrapper_item"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ItemHeartWrapper_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTile_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItemProvider_item"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemPhotoVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webPath",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ecommerceTrackingParams",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "9fee98b52f03f9c77b267f55b524e02f";

export default node;
