/**
 * @generated SignedSource<<adcc9ebe561e199ade7bf68b087c5070>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontProductTile_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemHeartWrapper_viewer">;
  readonly " $fragmentType": "StorefrontProductTile_viewer";
};
export type StorefrontProductTile_viewer$key = {
  readonly " $data"?: StorefrontProductTile_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductTile_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontProductTile_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "loadPortfolioData",
          "variableName": "hasUserId"
        },
        {
          "kind": "Variable",
          "name": "userIds",
          "variableName": "userIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemHeartWrapper_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "dc02e0f25895beaf6d94f824ca0cba43";

export default node;
