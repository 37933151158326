import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const srcSetOptions = {
    sizes: '100vw',
    photoQuality: 60,
    srcSet: [{ width: 250 }, { width: 500 }, { width: 700 }, { width: 1000 }],
};

function getSrcSetAttr(path, useSrcSet) {
    if (!useSrcSet) {
        return {};
    }

    const { srcSet, photoQuality } = srcSetOptions;
    const basePath = `${path}?quality=${photoQuality}&`;
    const srcSetArr = srcSet.map(({ width }) => `${basePath}width=${width} ${width}w`);

    return {
        srcSet: srcSetArr.join(', '),
        sizes: srcSetOptions.sizes,
    };
}

function MediaItem({ mediaType, url, path, caption, useSrcSet }) {
    let component = null;

    if (mediaType === 'PHOTO') {
        component = <img className={styles.photo} src={path} {...getSrcSetAttr(path, useSrcSet)} />;
    } else if (mediaType === 'VIDEO') {
        component = <iframe className={styles.video} src={url} frameBorder="0" allowFullScreen />;
    }

    return (
        <Fragment>
            <div className={styles.mediaWrapper}>
                <div className={styles.mediaContainer}>
                    {/* Media (Photo / Video) */}
                    {component}
                </div>
            </div>

            {/* Caption */}
            {caption ? <p className={styles.caption}>{caption}</p> : null}
        </Fragment>
    );
}

MediaItem.propTypes = {
    url: PropTypes.string,
    path: PropTypes.string,
    caption: PropTypes.string,
    mediaType: PropTypes.oneOf(['PHOTO', 'VIDEO']).isRequired,
    useSrcSet: PropTypes.bool.isRequired,
};

export default MediaItem;
