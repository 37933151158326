import { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import styles from './styles/StorefrontDetailsDistinction.scss';
import classnames from 'classnames';

export class StorefrontDetailsDistinction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
            load: false,
        };
    }

    render() {
        const { title, description, icon: Icon, dataTn } = this.props;
        const iconClassName = classnames(this.props.customIconStyles, styles.icon);

        const { isHovered } = this.state;
        const mouseProps = description && {
            onMouseEnter: () => this.setState({ isHovered: !isHovered }),
            onMouseLeave: () => this.setState({ isHovered: !isHovered }),
        };

        return (
            <div {...mouseProps} className={styles.distinction} data-tn={dataTn}>
                <Icon className={iconClassName} />
                <span className={styles.copy}>{title}</span>

                {description && (
                    <Tooltip isVisible={isHovered} direction="bottom" dataTn="tooltip-recognized">
                        <div className={styles.tooltipText}>{description}</div>
                    </Tooltip>
                )}
            </div>
        );
    }
}

StorefrontDetailsDistinction.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
    icon: PropTypes.func.isRequired,
    dataTn: PropTypes.string,
    customIconStyles: PropTypes.string,
};
