import { FC, ReactNode, useState } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { useRelayEnvironment } from 'react-relay';

import SharedCallSellerForm from './SharedCallSellerForm';

import { PhoneNumberInput } from 'dibs-phone-number-input/exports/PhoneNumber';

import styles from './styles.scss';

export type Props = {
    showAllowCallbackPrompt?: boolean;
    phoneCallbackEnabled?: boolean;
    error?: ReactNode;
    submitting?: boolean;
    setPhoneCallbackEnabled: (checked: boolean) => void;
    onSubmit: () => void;
    onChange: ({
        value,
        countryCallingCode,
    }: {
        value: string;
        countryCallingCode?: string;
    }) => void;
    countryCode: string;
    phoneNumber: string;
};

const CallSellerForm: FC<Props> = ({
    showAllowCallbackPrompt,
    phoneCallbackEnabled,
    submitting,
    error,
    setPhoneCallbackEnabled,
    onSubmit,
    onChange,
    countryCode,
    phoneNumber: phoneNumberProp,
}) => {
    const [isValidNumber, setIsValidNumber] = useState(false);
    const submitDisabled = submitting || !isValidNumber;
    const environment = useRelayEnvironment();
    const changeHandler = ({
        value,
        isValid = false,
        countryCallingCode,
    }: {
        value: string;
        isValid?: boolean;
        countryCallingCode?: string;
    }): void => {
        setIsValidNumber(isValid);
        onChange({ value, countryCallingCode });
    };

    return (
        <form
            className={styles.form}
            onSubmit={e => {
                e.preventDefault();
                onSubmit();
            }}
        >
            {error ? <div className={styles.error}>{error}</div> : null}
            <div className={styles.inputsRow}>
                <PhoneNumberInput
                    wrapperClass={styles.phoneNumber}
                    environment={environment}
                    dataTn="call-dealer-form-number"
                    label={
                        <FormattedMessage
                            id="dc.buyerInitiateCall.nonTradeInputPhoneNumberNew"
                            defaultMessage="Your number"
                        />
                    }
                    onChange={changeHandler}
                    onFocus={changeHandler}
                    disabled={submitting}
                    flagWrapperPosition="bottom"
                    countryCode={countryCode}
                    phoneNumber={phoneNumberProp}
                    autoFocus
                />
            </div>
            <SharedCallSellerForm
                phoneCallbackEnabled={phoneCallbackEnabled}
                submitting={submitting}
                setPhoneCallbackEnabled={setPhoneCallbackEnabled}
                showAllowCallbackPrompt={showAllowCallbackPrompt}
                submitDisabled={submitDisabled}
            />
        </form>
    );
};

export default CallSellerForm;
