import { Component } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import { Star } from '../Star';

import styles from './main.scss';

type StarRatingProps = {
    rating?: number;
    size?: 'medium' | 'small' | 'large' | 'x-small'; // add new sizes as needed
    dataTn?: string;
    inline?: boolean;
    onStarClick?: (clickedStar: number) => void;
    isSelectable?: boolean;
};
type StarRatingState = {
    starKey: number;
    hoveredStar?: number;
};

type StarWrapperProps = {
    key: string;
    className: string;
    'data-tn': string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
};
let starCounter = 0; // each Star needs a unique id for it's `key` value
export class StarRating extends Component<StarRatingProps, StarRatingState> {
    constructor(props: StarRatingProps) {
        super(props);
        this.state = {
            starKey: starCounter++,
        };
    }

    mouseEnterStar(starIndex: number): void {
        this.setState({ hoveredStar: starIndex });
    }

    mouseLeaveStar(): void {
        this.setState({ hoveredStar: undefined });
    }

    render(): React.ReactNode {
        const { starKey, hoveredStar } = this.state;
        const {
            rating = 0,
            size = 'large',
            isSelectable = false,
            dataTn = 'star-rating',
            inline = false,
            onStarClick,
        } = this.props;

        const wrapperClass = classnames(styles.wrapper, {
            [styles.inlineWrapper]: inline,
        });

        const starWrapperClass = classnames(styles.star, {
            [styles.medium]: size === 'medium',
            [styles.small]: size === 'small',
            [styles.large]: size === 'large',
            [styles.xSmall]: size === 'x-small',
            [styles.selectable]: isSelectable,
        });

        const stars = [];
        for (let currentStarIndex = 0; currentStarIndex < 5; currentStarIndex++) {
            let percentFilled;
            let showBorder = false;
            if (currentStarIndex <= rating - 1) {
                // full star
                percentFilled = 100;
            } else if (currentStarIndex < rating) {
                // partially full star
                percentFilled = (rating - currentStarIndex) * 100;
            } else {
                // empty star
                percentFilled = 0;
                showBorder =
                    isSelectable &&
                    typeof hoveredStar !== 'undefined' &&
                    hoveredStar >= currentStarIndex;
            }

            const baseStarWrapperProps = {
                key: `StarRatingStar-${starKey}-${currentStarIndex}`,
                className: starWrapperClass,
                'data-tn': `${dataTn}-star`,
            };
            let starWrapperProps: StarWrapperProps = {
                ...baseStarWrapperProps,
            };
            if (isSelectable) {
                starWrapperProps = {
                    ...baseStarWrapperProps,
                    onMouseEnter: () => this.mouseEnterStar(currentStarIndex),
                    onMouseLeave: () => this.mouseLeaveStar(),
                };
            }
            if (onStarClick) {
                starWrapperProps = {
                    ...baseStarWrapperProps,
                    onClick: () => onStarClick(currentStarIndex + 1),
                };
            }
            stars.push(
                <div {...starWrapperProps}>
                    <Star percentFilled={percentFilled} size={size} showBorder={showBorder} />
                </div>
            );
        }
        return (
            <div className={wrapperClass} data-tn={dataTn} data-value={rating}>
                {stars}
            </div>
        );
    }
}
