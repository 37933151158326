/**
 * @generated SignedSource<<ff1de9400e6629cfc5a27a93fe1c12d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontFeaturedSection_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductGrid_user">;
  readonly " $fragmentType": "StorefrontFeaturedSection_user";
};
export type StorefrontFeaturedSection_user$key = {
  readonly " $data"?: StorefrontFeaturedSection_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontFeaturedSection_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontFeaturedSection_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontProductGrid_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "cc33e765bcfb2cbd86cb6ba9c7c0cfd9";

export default node;
