import { graphql, Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';

import {
    InitiateCallMutation,
    InitiateCallMutation$data,
    InitiateCallInput,
} from './__generated__/InitiateCallMutation.graphql';

const mutation = graphql`
    mutation InitiateCallMutation($input: InitiateCallInput!) {
        initiateCall(input: $input) {
            success
            errors {
                message
            }
        }
    }
`;

export default function initiateCall(
    environment: Environment,
    input: InitiateCallInput
): Promise<InitiateCallMutation$data> {
    return commitMutation<InitiateCallMutation>(environment, {
        mutation,
        variables: { input },
    });
}
