import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';

const { EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;
const {
    STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
    STEP_INTERACTION_ITEM_REMOVED_FROM_FOLDER,
    STEP_INTERACTION_ITEM_FOLDER_CLICKED,
    STEP_INTERACTION_ITEM_FOLDER_CREATED,
} = stepInteractionConstants;

function trackFolderEvent(step_interaction_name, noteIncluded = false, itemPk = null) {
    const trigger = 'search/browse';
    const hasNoteIncluded = noteIncluded ? 1 : 0;
    let eventInfo = {
        label: trigger,
        action: step_interaction_name,
        category: INTERACTION_TYPE_ITEM_FAVORITING,
        value: hasNoteIncluded,
        isInteractiveEvent: true,
        eventName: EVENT_WISHLIST,
        interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
        step_interaction_name,
        trigger,
        step_type: hasNoteIncluded,
    };

    if (itemPk) {
        eventInfo = { ...eventInfo, additional: { itemPk } };
    }

    trackEvent(eventInfo, null);
}

export function trackFolderClick() {
    trackFolderEvent(STEP_INTERACTION_ITEM_FOLDER_CLICKED);
}

export function trackItemRemoved() {
    trackFolderEvent(STEP_INTERACTION_ITEM_REMOVED_FROM_FOLDER);
}

export function trackItemAdded(noteIncluded = false, itemPk = null) {
    const trigger = 'search/browse';
    const hasNoteIncluded = noteIncluded ? 1 : 0;
    trackEvent(
        {
            label: trigger,
            action: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
            category: INTERACTION_TYPE_ITEM_FAVORITING,
            value: hasNoteIncluded,
            isInteractiveEvent: true,
            additional: itemPk ? { itemPk } : {},
            eventName: EVENT_WISHLIST,
            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
            trigger: trigger,
            step_type: hasNoteIncluded,
        },
        null
    );
}

export function trackFolderCreated(noteIncluded = false) {
    trackFolderEvent(STEP_INTERACTION_ITEM_FOLDER_CREATED, noteIncluded);
}

export default {
    trackFolderClick,
    trackItemRemoved,
    trackItemAdded,
    trackFolderCreated,
};
