import { graphql } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
    mutation storefrontFollowDealerMutation($input: FavoriteSellerInput!, $userId: String!) {
        favoriteSeller(input: $input) {
            seller {
                favoritesByUser(userIds: $userId) {
                    serviceId
                }
            }
        }
    }
`;

export function storefrontFollowDealer(environment, { userId, sellerPk, favoriteId }) {
    const variables = {
        userId,
        input: {
            userId,
            sellerPk,
            favoriteId,
        },
    };
    return commitMutation(environment, { mutation, variables });
}
