export const prepShareWindow = () => {
    const width = 665;
    const height = 500;

    const windowOptions = {
        scrollbars: 'yes',
        resizable: 'no',
        toolbar: 'no',
        height: height,
        width: width,
        top: 0,
        left: 0,
    };

    const screenHeight = screen.height;
    const screenWidth = screen.width;

    const halfScreenWidth = screenWidth / 2;
    const halfWidth = width / 2;
    windowOptions.left = Math.round(halfScreenWidth - halfWidth);

    if (screenHeight > height) {
        const halfScreenHeight = screenHeight / 2;
        const halfHeight = height / 2;
        windowOptions.top = Math.round(halfScreenHeight - halfHeight);
    }

    return Object.keys(windowOptions)
        .reduce((prev, curr) => {
            prev.push(`${curr}=${windowOptions[curr]}`);
            return prev;
        }, [])
        .join(',');
};

export const shareViaFacebook = () => {
    if (!window.FB || !window.FB.ui) {
        return false;
    }

    window.FB.ui({
        //@ts-ignore TODO: ENGAGE-1738
        method: 'stream.share',
    });

    return true;
};

export const shareViaPinterest = ({ description, media, url }) => {
    const windowString = prepShareWindow();
    const pinWindow = window.open('', 'pinterest', windowString);

    const params = new URLSearchParams({
        url,
        media,
        description,
    });

    pinWindow.location = `https://pinterest.com/pin/create/button/?${params.toString()}`;

    return true;
};

export const shareViaShareScreen = ({ title, text, url }) => {
    const shareAvailable = !!(navigator && navigator.share);

    if (shareAvailable) {
        navigator.share({ title, text, url });
    }

    return shareAvailable;
};
