import { FormattedMessage } from 'dibs-react-intl';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import get from 'lodash.get';

import MediaCarousel from './carousel/MediaCarousel';
import StorefrontSectionHeader from './StorefrontSectionHeader';

import styles from './styles/StorefrontMediaSection.scss';
import { ServerVarsContextConsumer } from '../../sharedComponents/ServerVarsContext';

function getMediaList(seller) {
    const mediaList = get(seller, 'storefrontProfile.galleryAlbum.mediaList');
    return mediaList && mediaList.length ? mediaList : null;
}

function StorefrontMediaSection({ seller }) {
    const mediaList = getMediaList(seller);

    if (!mediaList) {
        return null;
    }

    return (
        <div className={styles.section}>
            <StorefrontSectionHeader
                header={
                    <FormattedMessage
                        id="StorefrontMediaSection.header"
                        defaultMessage="More About {company}"
                        values={{ company: get(seller, 'sellerProfile.company') }}
                    />
                }
            />
            <div className={styles.content}>
                <ServerVarsContextConsumer>
                    {({ isMobile }) => (
                        <MediaCarousel useSrcSet mediaList={mediaList} isMobile={isMobile} />
                    )}
                </ServerVarsContextConsumer>
            </div>
        </div>
    );
}

StorefrontMediaSection.propTypes = {
    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontMediaSection, {
    seller: graphql`
        fragment StorefrontMediaSection_seller on Seller {
            sellerProfile {
                company
            }
            storefrontProfile(storefrontId: $storefrontId) {
                galleryAlbum {
                    mediaList {
                        sortOrder
                        element {
                            ... on Photo {
                                serviceId
                                path
                                caption
                                mediaType
                            }
                            ... on Video {
                                serviceId
                                url
                                caption
                                mediaType
                            }
                        }
                    }
                }
            }
        }
    `,
});
