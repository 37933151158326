import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import PropTypes from 'prop-types';
import { storefrontEnsureLogin } from '../mutations/storefrontEnsureLogin';
import { isEmailOnly } from 'dibs-cookie-jar/exports/isEmailOnly';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { localStorage as dibsLocalStorage } from 'dibs-browser-storage';
import CallDealerModal from 'dibs-buyer-initiate-call/exports/CallDealerModal';
import { Link } from 'dibs-elements/exports/Link';

class CallButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (isEmailOnly(document.cookie)) {
            authModalLoader.show({
                action: authModalLoader.constants.REQUEST_PASSWORD_EMAIL,
                flow: authModalLoader.constants.CREATE_PASSWORD_CALL_SELLER_FLOW,
                email: dibsLocalStorage.getItem('userEmail'),
                relayEnvironment: this.props.relay.environment,
            });
        } else {
            this.setState({ isOpened: true });
        }
    }

    render() {
        const { isOpened } = this.state;
        const { userId, className } = this.props;

        return (
            <Link
                underline="none"
                className={className}
                onClick={this.onClick}
                dataTn="storefront-head-call-button"
            >
                {/* Call */}
                <FormattedMessage id="StorefrontCallButton.cta" defaultMessage="Call" />

                {/* Modal */}
                <CallDealerModal
                    location="storefront"
                    isOpened={isOpened}
                    userId={userId}
                    hasUserId={!!userId}
                    item={null}
                    conversation={null}
                    viewer={this.props.viewer}
                    seller={this.props.seller}
                    onClose={() => this.setState({ isOpened: false })}
                    onAuthRequired={(onAuthSuccess, onAuthFailure) => {
                        storefrontEnsureLogin({
                            relayEnvironment: this.props.relay.environment,
                            authOptions: {
                                ga: {
                                    label: 'in dealer storefront - call button',
                                },
                            },
                        })
                            .then(({ userId: newUserId }) => onAuthSuccess(newUserId))
                            .catch(onAuthFailure);
                    }}
                />
            </Link>
        );
    }
}

CallButton.propTypes = {
    className: PropTypes.string.isRequired,

    userId: PropTypes.string,
    viewer: PropTypes.object.isRequired,
    seller: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,

    followDealer: PropTypes.func.isRequired,
};

export default createFragmentContainer(CallButton, {
    seller: graphql`
        fragment StorefrontCallButton_seller on Seller {
            ...CallDealerModal_seller
        }
    `,
    viewer: graphql`
        fragment StorefrontCallButton_viewer on Viewer {
            ...CallDealerModal_viewer
        }
    `,
});
