import PropTypes from 'prop-types';
import { BarHeader } from 'dibs-elements/exports/BarHeader';

function StorefrontSectionHeader({ header }) {
    return <BarHeader title={header} size="xLarge" htmlElementType="h2" />;
}

StorefrontSectionHeader.propTypes = {
    header: PropTypes.node.isRequired,
};

export default StorefrontSectionHeader;
