import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Checkbox } from 'dibs-elements/exports/Checkbox';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { Button } from 'dibs-elements/exports/Button';

import styles from './styles.scss';
type Props = {
    showAllowCallbackPrompt?: boolean;
    phoneCallbackEnabled?: boolean;
    submitting?: boolean;
    setPhoneCallbackEnabled: (checked: boolean) => void;
    submitDisabled: boolean;
};

const SharedCallDealerForm: FC<Props> = ({
    showAllowCallbackPrompt,
    setPhoneCallbackEnabled,
    phoneCallbackEnabled,
    submitDisabled,
    submitting,
}) => {
    return (
        <>
            {showAllowCallbackPrompt ? (
                <div className={styles.allowCallbackPromptContainer}>
                    <Checkbox
                        name="showAllowCallbackPrompt"
                        onChange={setPhoneCallbackEnabled}
                        dataTn="allow-callback-prompt"
                        label={
                            <FormattedMessage
                                id="dc.buyerInitiateCall.shared.callbackPrompt"
                                defaultMessage="Allow seller to call you back"
                            />
                        }
                        labelClass={styles.checkboxLabel}
                        checked={phoneCallbackEnabled}
                    />
                    <span className={styles.allowCallbackDetails}>
                        <FormattedMessage
                            id="dc.buyerInitiateCall.shared.callbackDetails"
                            defaultMessage="We will not share your number with the seller."
                        />
                    </span>
                </div>
            ) : null}
            <Button
                className={styles.submit}
                disabled={submitDisabled}
                size="large"
                dataTn="connect-call"
            >
                {submitting && <Spinner dark containerClass={styles.inlineSpinner} />}
                <FormattedMessage
                    id="dc.buyerInitiateCall.shared.submitCall"
                    defaultMessage="Call me"
                />
            </Button>
            <p className={styles.footer}>
                <FormattedMessage
                    id="dc.buyerInitiateCall.shared.formFooter"
                    defaultMessage="International calling rates will not apply. If the seller doesn’t answer, you’ll be able to leave a message."
                />
            </p>
        </>
    );
};
export default SharedCallDealerForm;
