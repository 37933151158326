// Rip off logic used internally by React.PropTypes to create chainable/requirable prop type validators. The React
// team has not agreed to expose this so we'll need to maintain it ourselves. Some minor changes made to the
// implementation that shouldn't affect its operation.

'use strict';

const ANONYMOUS = '<<anonymous>>';

/**
 * Pass in a custom React prop types validation function to create a wrapped version of that validator that allows
 * marking it as required.
 *
 * See https://facebook.github.io/react/docs/reusable-components.html for some more information on custom validators.
 * @param  {Function} validate The validation function to wrap.
 * @return {Function}          Wrapped validator.
 */
function createChainableTypeChecker(validate) {
    function checkType(isRequired, props, propName, componentName, location, propFullName) {
        componentName = componentName || ANONYMOUS;
        propFullName = propFullName || propName;
        const { [propName]: prop } = props;

        if (prop === null || prop === undefined) {
            if (isRequired) {
                return new Error(
                    `Required ${location} \`${propFullName}\` was not specified in \`${componentName}\``
                );
            } else {
                return null;
            }
        } else {
            return validate(props, propName, componentName, location, propFullName);
        }
    }

    // Create a required and non-required version of the validator
    const chainedCheckType = checkType.bind(null, false);
    chainedCheckType.isRequired = checkType.bind(null, true);

    return chainedCheckType;
}

module.exports = createChainableTypeChecker;
