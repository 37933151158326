// Flow types automatically removed

import styles from './styles.scss';

/**
 * ProfileCoverLayout (Presentational Component)
 */
function ProfileCoverLayout(props) {
    const { className, width, coverPhoto, components } = props;
    const backgroundImage = coverPhoto ? `url('${coverPhoto}')` : 'none';
    const { PictureRenderer, DetailsRenderer, TopRenderer, SocialRenderer, TabsRenderer } =
        components;
    return (
        <div
            className={className}
            style={{
                backgroundImage,
            }}
        >
            {/* Top Content */}
            <div className={styles.top}>{TopRenderer ? <TopRenderer /> : null}</div>

            {/* Bottom Content */}
            <div className={styles.bottom}>
                {/* Left */}
                <div
                    className={styles.left}
                    style={{
                        width,
                    }}
                >
                    <div className={styles.leftContent}>
                        {/* Picture */}
                        <div className={styles.pictureWrapper}>
                            {PictureRenderer ? <PictureRenderer /> : null}
                        </div>
                    </div>
                </div>

                {/* Right */}
                <div className={styles.right}>
                    <div className={styles.rightContent}>
                        {/* Details */}
                        <div className={styles.detailsWrapper}>
                            {DetailsRenderer ? <DetailsRenderer /> : null}
                        </div>
                        {/* Social */}
                        <div className={styles.socialWrapper}>
                            {SocialRenderer ? <SocialRenderer /> : null}
                        </div>
                        {/* Tabs */}
                        <div className={styles.tabsWrapper}>
                            {TabsRenderer ? <TabsRenderer /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileCoverLayout;
