import { trackEvent, eventNameConstants } from 'dibs-tracking';

export const locations = {
    DESIGN_SERIES: 'DESIGN_SERIES',
};

export const authLabels = {
    [locations.DESIGN_SERIES]: 'save search - design series',
};

export function trackFollowSearch({ label, isAdded }: { label: string; isAdded: boolean }): void {
    const category = 'search favoriting';
    const action = `search ${isAdded ? 'added' : 'removed'}`;

    trackEvent({
        category,
        eventName: eventNameConstants.EVENT_WISHLIST,
        action,
        interaction_type: category,
        label,
        step_interaction_name: action,
    });
}
