import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import get from 'lodash.get';

import StorefrontAboutSection from './StorefrontAboutSection';
import StorefrontMediaSection from './StorefrontMediaSection';
import StorefrontFeaturedSection from './StorefrontFeaturedSection';
import StorefrontCreatorsUrls from './StorefrontCreatorsUrls';

function StorefrontHome({ ...props }) {
    const seller = props?.seller;
    const storefrontData = props?.storefrontData;
    return (
        <Fragment>
            {/* About {Seller} */}
            <StorefrontAboutSection seller={seller} sellerData={props.sellerData} />

            {/* Featured Pieces */}
            {get(props, 'storefrontData.edges.length') ? (
                <StorefrontFeaturedSection
                    isPreview={props.isPreview}
                    seller={seller}
                    isTrade={props.isTrade}
                    user={props.user}
                    viewer={props.viewer}
                    products={storefrontData}
                />
            ) : null}

            {/* Media Section */}
            <StorefrontMediaSection seller={seller} />

            {/* Creators URLs */}
            <StorefrontCreatorsUrls featuredCreatorsUrls={storefrontData} />
        </Fragment>
    );
}

StorefrontHome.propTypes = {
    isTrade: PropTypes.bool,
    isPreview: PropTypes.bool.isRequired,
    sellerData: PropTypes.shape({
        dealerSince: PropTypes.string.isRequired,
    }).isRequired,

    // fetched data
    user: PropTypes.object,
    viewer: PropTypes.object.isRequired,
    seller: PropTypes.object.isRequired,
    products: PropTypes.object,
    creatorsUrls: PropTypes.object,
};

export default createFragmentContainer(StorefrontHome, {
    viewer: graphql`
        fragment StorefrontHome_viewer on Viewer {
            ...StorefrontFeaturedSection_viewer
        }
    `,
    user: graphql`
        fragment StorefrontHome_user on User {
            ...StorefrontFeaturedSection_user
        }
    `,
    seller: graphql`
        fragment StorefrontHome_seller on Seller {
            ...StorefrontAboutSection_seller
            ...StorefrontMediaSection_seller
            ...StorefrontFeaturedSection_seller
        }
    `,
    storefrontData: graphql`
        fragment StorefrontHome_storefrontData on DealerStorefrontQueryConnection {
            edges {
                node {
                    serviceId
                }
            }
            ...StorefrontFeaturedSection_products
            ...StorefrontCreatorsUrls_featuredCreatorsUrls
        }
    `,
});
