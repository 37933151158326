/**
 * @generated SignedSource<<02466060ae81bc845e322e2ea0a22872>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CallDealerModal_viewer$data = {
  readonly primaryPhone?: {
    readonly countryCode: string | null;
    readonly countryCodeNumber: string | null;
    readonly formattedPhoneNumber: string | null;
    readonly isActive: boolean | null;
  } | null;
  readonly viewerUser?: {
    readonly profile: {
      readonly displayName: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "CallDealerModal_viewer";
};
export type CallDealerModal_viewer$key = {
  readonly " $data"?: CallDealerModal_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CallDealerModal_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CallDealerModal_viewer",
  "selections": [
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "viewerUser",
          "args": (v0/*: any*/),
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Profile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "preventEmailFallback",
                      "value": true
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": "displayName(preventEmailFallback:true)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "Phone",
          "kind": "LinkedField",
          "name": "primaryPhone",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countryCodeNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countryCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formattedPhoneNumber",
              "storageKey": null
            },
            {
              "alias": "isActive",
              "args": [
                {
                  "kind": "Literal",
                  "name": "status",
                  "value": "ACTIVE"
                }
              ],
              "kind": "ScalarField",
              "name": "hasStatus",
              "storageKey": "hasStatus(status:\"ACTIVE\")"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "74da132141f6c88db3c7d5595de3a448";

export default node;
