/**
 * @generated SignedSource<<1c3dc9bcdb8af59ae9cdcbb87a46dc5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontProductGrid_products$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly categoryCode: string | null;
      readonly categoryPath: string | null;
      readonly ecommerceTrackingParams: any | null;
      readonly serviceId: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductTile_product">;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "StorefrontProductGrid_products";
};
export type StorefrontProductGrid_products$key = {
  readonly " $data"?: StorefrontProductGrid_products$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductGrid_products">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontProductGrid_products",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DealerStorefrontQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Item",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categoryPath",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categoryCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ecommerceTrackingParams",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StorefrontProductTile_product"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DealerStorefrontQueryConnection",
  "abstractKey": null
};

(node as any).hash = "7f0a0a6d19b0517fc1d43c36783d7e8e";

export default node;
