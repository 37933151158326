/**
 * @generated SignedSource<<74ac23aff48889c8513c65e2277677b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontContactModal_seller$data = {
  readonly sellerPreferences: {
    readonly displayStoreAddress: boolean | null;
    readonly storeAddressByAppointmentOnly: boolean | null;
  } | null;
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly shippingAddress: {
    readonly displayCityStateZip: string | null;
    readonly displayCountry: string | null;
    readonly line1: string | null;
    readonly line2: string | null;
  } | null;
  readonly tradeAssociations: ReadonlyArray<{
    readonly displayName: string | null;
  } | null> | null;
  readonly " $fragmentType": "StorefrontContactModal_seller";
};
export type StorefrontContactModal_seller$key = {
  readonly " $data"?: StorefrontContactModal_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontContactModal_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontContactModal_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerPreferences",
      "kind": "LinkedField",
      "name": "sellerPreferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayStoreAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "storeAddressByAppointmentOnly",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "line1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "line2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayCityStateZip",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeAssociation",
      "kind": "LinkedField",
      "name": "tradeAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "cafb8927ec756212aea69ec0d8198adf";

export default node;
