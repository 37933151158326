import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles/StorefrontHeadshot.scss';

function StorefrontHeadshot({ additionalClasses, imagePath }) {
    return (
        <div className={classNames(styles.headshot, additionalClasses.headshot)}>
            <img className={styles.image} src={imagePath} />
        </div>
    );
}

StorefrontHeadshot.propTypes = {
    additionalClasses: PropTypes.shape({
        headshot: PropTypes.string,
    }),
    imagePath: PropTypes.string.isRequired,
};

export default StorefrontHeadshot;
