/**
 * @generated SignedSource<<c236d343f392c0e6e0db3d679423a754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontSocialShare_seller$data = {
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly " $fragmentType": "StorefrontSocialShare_seller";
};
export type StorefrontSocialShare_seller$key = {
  readonly " $data"?: StorefrontSocialShare_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontSocialShare_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontSocialShare_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "dc831e75325ac9ff6898dfc6223ab2ff";

export default node;
