'use strict';

/**
 * Uppercase the first letter of the passed in string.
 * @param  {String} str String to capitalize
 * @return {String}
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
}

/**
 * Generate an enum type with the passed in variants. Enums will get a match method, a method for getting the raw
 * type name as a string a method for getting any contained data, and identity functions for every variant. An example
 * illustrates how this works:
 *
 * Here each variant wraps a class. When matching on the variant, the inner data is returned and can be manipulated.
 * const deviceTypeEnum = createEnum({
 *     mobile(...args) {
 *         return new Mobile(...args);
 *     },
 *     tablet(...args) {
 *         return new Tablet(...args);
 *     },
 *     desktop(...args) {
 *         return new Desktop(...args);
 *     }
 * });
 *
 * const instance = deviceTypeEnum.mobile();
 *
 * instance.isMobile() // true
 * instance.isTablet() // false
 * instance.isDesktop() // false
 * instance.variant() // 'mobile'
 * instance.data() // instance of class Mobile
 *
 * const test = instance.match({
 *     mobile: (inner) => inner,
 *     tablet: (inner) => inner,
 *     desktop: (inner) => inner
 * });
 *
 * console.log(test) // instance of class Mobile
 *
 * @param  {String} ...types List of enum variants.
 * @return {Enum}
 */
function createEnum(...variants) {
    // Allow passing a list of variants instead of an object if we don't need to hold any data.
    if (typeof variants[0] === 'object') {
        variants = variants[0];
    } else {
        variants = variants.reduce((acc, variant) => {
            if (typeof variant !== 'string') {
                throw new Error(
                    'If not passing a config object to createEnum, all arguments must be strings.'
                );
            }

            acc[variant] = () => null;
            return acc;
        }, {});
    }

    // Brand the newly create enum "class" so instance of differentiates between different enums.
    // Symbols are guaranteed to be unique so there is no way to spoof this.
    const brand = Symbol();
    const keys = Object.keys(variants);

    const EnumClass = class {
        constructor(variant, data) {
            this.variant = variant;
            this.data = data;
            this[brand] = true;
        }

        // Matching function to avoid lots of if/else blocks using the identity methods directly.
        match(config) {
            // eslint-disable-line consistent-return
            const matcher = config[this.variant];

            // If a matching function is passed in, pass through the wrapped data.
            if (matcher) {
                return matcher(this.data);
            } else {
                return void 0;
            }
        }
    };

    // Add an "isVariant" method for each variant.
    keys.forEach(variant => {
        EnumClass.prototype[`is${capitalize(variant)}`] = function () {
            return this.variant === variant;
        };
    });

    // Freeze the object so it can't be tampered with.
    Object.freeze(EnumClass.prototype);
    return Object.freeze(
        keys.reduce(
            (acc, variant) => {
                // Add the variant construction functions.
                acc[variant] = (...args) =>
                    Object.freeze(new EnumClass(variant, variants[variant](...args)));
                return acc;
            },
            {
                // Check for the brand in instanceof.
                [Symbol.hasInstance](value) {
                    return value[brand] || false;
                },
            }
        )
    );
}

module.exports = createEnum;
