import { PureComponent, ReactElement } from 'react';
import { createFragmentContainer, graphql, RelayProp } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';

import { Header } from 'dibs-elements/exports/Header';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { ModalBackButton } from 'dibs-elements/exports/ModalBackButton';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import Iphone from 'dibs-icons/exports/legacy/Iphone';
import TradeArrow from 'dibs-icons/exports/legacy/TradeArrow';
import NumberInputDisplay from 'dibs-icons/exports/legacy/NumberInputDisplay';
import PhoneRinging from 'dibs-icons/exports/legacy/PhoneRinging';

import CallSellerForm from '../CallDealerForm/CallSellerForm';
import CallInitiatedSuccess from '../CallInitiatedSuccess/CallInitiatedSuccess';
import { onCallModalOpen, onCallModalSuccess, CallSellerModalLocation } from '../../tracking';
import initiateCallMutation from '../../mutations/InitiateCall';
import styles from './styles.scss';
import { CallDealerModal_viewer$data } from './__generated__/CallDealerModal_viewer.graphql';
import { CallDealerModal_seller$data } from './__generated__/CallDealerModal_seller.graphql';
import { CallDealerModal_item$data } from './__generated__/CallDealerModal_item.graphql';
import { CallDealerModal_conversation$data } from './__generated__/CallDealerModal_conversation.graphql';

type Props = {
    relay: RelayProp;
    userId?: string;
    isOpened: boolean;
    hasUserId?: boolean;
    onClose: () => void;
    onAuthRequired?: (
        onAuthSuccess: (buyerId: string) => Promise<void>,
        onAuthFailure: () => void
    ) => void;
    viewer: CallDealerModal_viewer$data;
    seller: CallDealerModal_seller$data | null | undefined;
    item: CallDealerModal_item$data | null | undefined;
    conversation: CallDealerModal_conversation$data | null | undefined;
    location: CallSellerModalLocation;
};

type State = {
    name: string;
    countryCallingCode: string;
    countryCode: string;
    phoneNumber: string;
    savePhoneNumber: boolean;
    phoneCallbackEnabled: boolean;
    submitting: boolean;
    success: boolean;
    error?: ReactElement | string;
};

const defaultCountryCode = '1';
const defaultCountry = 'US';
const numbersOnlyRegex = /^\d+$/;
const containsLettersRegex = /[a-z]/i;

const unknownServiceError = (
    <FormattedMessage
        id="dc.buyerInitiateCall.unknown_service_error"
        defaultMessage="Unknown error occurred. Please try again later."
    />
);

const invalidInput = (
    <FormattedMessage
        id="dc.buyerInitiateCall.invalid_input"
        defaultMessage="Phone number format is invalid. Include a country code and the correct number of digits."
    />
);

const initialState = ({ viewer, conversation }: Props): State => {
    const name = viewer.viewerUser?.profile?.displayName || '';
    const { primaryPhone } = viewer || {};
    const existingPhone = !!primaryPhone?.isActive;
    const phoneCallbackEnabled = conversation?.currentConversationMember?.phoneCallbackEnabled;
    const { countryCodeNumber, countryCode, formattedPhoneNumber } = primaryPhone || {};
    return {
        name,
        countryCallingCode: (existingPhone && countryCodeNumber) || defaultCountryCode,
        countryCode: (existingPhone && countryCode) || defaultCountry,
        phoneNumber: (existingPhone && formattedPhoneNumber) || '',
        savePhoneNumber: !existingPhone,
        phoneCallbackEnabled: phoneCallbackEnabled !== false,
        submitting: false,
        success: false,
        error: undefined,
    };
};

export class CallDealerModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = initialState(props);

        if (!props.userId && !props.onAuthRequired) {
            throw new Error(
                'No userId prop given. Must have a handler for when auth is then required.'
            );
        }
        if (props.isOpened && props.location) {
            onCallModalOpen(props.location);
        }

        this.initiateCall = this.initiateCall.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFailure = this.onFailure.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        const previousPhone = this.props.viewer.primaryPhone;
        const currentPhone = nextProps.viewer.primaryPhone;
        const updatePhone = !previousPhone && currentPhone;

        if (!this.state.submitting && updatePhone) {
            this.setState(() => initialState(nextProps));
        }
        if (!this.props.isOpened && nextProps.isOpened && nextProps.location) {
            onCallModalOpen(nextProps.location);
        }
    }

    async initiateCall(buyerId: string): Promise<void> {
        const { seller, item, relay, location } = this.props;
        const { name, phoneNumber, countryCallingCode, savePhoneNumber, phoneCallbackEnabled } =
            this.state;

        try {
            const response = await initiateCallMutation(relay.environment, {
                buyerId,
                saveName: false,
                savePhoneNumber,
                buyerName: name,
                // strip out non-numeric characters
                buyerNumber: phoneNumber.replace(/\D/g, ''),
                buyerCountryCode: Number(countryCallingCode),
                itemId: item?.serviceId,
                itemName: item?.title,
                dealerId: seller?.serviceId || '',
                phoneCallbackEnabled,
            });

            const { success, errors } = response?.initiateCall || {};

            if (success) {
                this.setState({
                    success: true,
                    submitting: false,
                    savePhoneNumber: false,
                });
                if (location) {
                    onCallModalSuccess(location, phoneCallbackEnabled);
                }
            } else if (errors && errors.length) {
                const firstError = errors[0];
                this.setState({
                    error: firstError?.message || unknownServiceError,
                    submitting: false,
                });
            } else {
                this.setState({ error: unknownServiceError, submitting: false });
            }
        } catch (error) {
            this.onFailure();
        }
    }

    onSubmit(): void {
        const { userId, onAuthRequired } = this.props;
        const { phoneNumber, countryCallingCode } = this.state;

        const invalidCountryCode = !numbersOnlyRegex.test(countryCallingCode);
        const invalidPhoneNumber = containsLettersRegex.test(phoneNumber);

        if (invalidCountryCode || invalidPhoneNumber) {
            this.setState({ error: invalidInput, submitting: false });
            return;
        }

        this.setState({ submitting: true, error: undefined });

        if (!userId) {
            onAuthRequired?.(
                this.initiateCall,
                () => this.setState({ submitting: false }) // if auth fails
            );
            return;
        }

        this.initiateCall(userId);
    }

    onFailure(): void {
        this.setState({ error: unknownServiceError, submitting: false });
    }

    onClose(): void {
        this.setState({ success: false });
        this.props.onClose();
    }

    render(): ReactElement {
        const { isOpened, seller } = this.props;
        const dealerLocation = seller?.shippingAddress?.displayCityStateCountry;

        let title;
        let header;
        let prompt;
        let subtitle;
        if (this.state.success) {
            title = (
                <span data-tn="call-initiated">
                    <FormattedMessage
                        id="dc.buyerInitiateCall.modalSuccessHeader"
                        defaultMessage="Expect Our Call"
                    />
                </span>
            );
            header = (
                <div className={styles.wrapper}>
                    <div className={styles.confirmationHeader}>
                        <PhoneRinging className={styles.iconRinging} />
                        <div className={styles.headerWrapper}>
                            <Header title={title} subtitle={subtitle} />
                        </div>
                        <div className={styles.stepDescription}>
                            <FormattedMessage
                                id="dc.buyerInitiateCall.call_initiated_description"
                                defaultMessage="When you answer, you’ll hear ringing until the seller picks up."
                            />
                        </div>
                    </div>
                </div>
            );
            prompt = <CallInitiatedSuccess />;
        } else {
            title = (
                <FormattedMessage
                    id="dc.buyerInitiateCall.modalFormHeader"
                    defaultMessage="Call the Seller"
                />
            );
            subtitle = dealerLocation ? (
                <FormattedMessage
                    id="dc.buyerInitiateCall.modalFormSubHeader"
                    defaultMessage="Seller's Location: {dealerLocation}"
                    values={{ dealerLocation }}
                />
            ) : null;

            header = (
                <div className={styles.wrapper}>
                    <div className={styles.headerWrapper}>
                        <Header title={title} subtitle={subtitle} />
                    </div>
                    <div className={styles.steps}>
                        <div className={styles.step}>
                            <NumberInputDisplay className={styles.icon} />
                            <div className={styles.stepDescription}>
                                <FormattedMessage
                                    id="dc.buyerInitiateCall.enterNumber"
                                    defaultMessage="First, enter your phone number below."
                                />
                            </div>
                        </div>
                        <TradeArrow className={styles.nextStepIcon} />
                        <div className={styles.step}>
                            <Iphone className={styles.icon} />
                            <div className={styles.stepDescription}>
                                <FormattedMessage
                                    id="dc.buyerInitiateCall.getCall"
                                    defaultMessage="Next, you’ll get a call, connecting you with the seller."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
            prompt = (
                <CallSellerForm
                    showAllowCallbackPrompt
                    phoneCallbackEnabled={this.state.phoneCallbackEnabled}
                    submitting={this.state.submitting}
                    error={this.state.error}
                    onChange={({
                        countryCallingCode = '',
                        value,
                    }: {
                        value: string;
                        countryCallingCode?: string;
                    }) => {
                        this.setState({
                            countryCallingCode: countryCallingCode?.replace('+', ''),
                            phoneNumber: value.replace(countryCallingCode, ''),
                            savePhoneNumber: true,
                            error: undefined,
                        });
                    }}
                    setPhoneCallbackEnabled={enabled =>
                        this.setState({ phoneCallbackEnabled: enabled })
                    }
                    onSubmit={this.onSubmit}
                    countryCode={this.state.countryCode}
                    phoneNumber={this.state.phoneNumber}
                />
            );
        }

        return (
            <ModalContainer isOpen={isOpened} onClose={this.onClose} modalPosition="center">
                {this.props.location === 'sellerProfile' ? (
                    <ModalBackButton className={styles.modalBackButton} onClick={this.onClose} />
                ) : null}
                <ModalCloseButton onClick={this.onClose} />
                {header}
                {prompt}
            </ModalContainer>
        );
    }
}

export default createFragmentContainer(CallDealerModal, {
    conversation: graphql`
        fragment CallDealerModal_conversation on Conversation {
            currentConversationMember: currentMember(currentUserId: $userId)
                @include(if: $hasUserId) {
                phoneCallbackEnabled
            }
        }
    `,
    item: graphql`
        fragment CallDealerModal_item on Item {
            serviceId
            title
        }
    `,
    seller: graphql`
        fragment CallDealerModal_seller on Seller {
            serviceId
            shippingAddress {
                displayCityStateCountry
            }
        }
    `,
    viewer: graphql`
        fragment CallDealerModal_viewer on Viewer {
            viewerUser: user(userId: $userId) @include(if: $hasUserId) {
                profile {
                    displayName(preventEmailFallback: true)
                }
            }
            primaryPhone(userId: $userId) @include(if: $hasUserId) {
                countryCodeNumber
                countryCode
                formattedPhoneNumber
                isActive: hasStatus(status: "ACTIVE")
            }
        }
    `,
});
