import { graphql } from 'react-relay/legacy';

export default graphql`
    query storefrontRootQuery(
        $userId: String = ""
        $userIds: [String]
        $hasUserId: Boolean!
        $isTrade: Boolean!
        $urlLabel: String!
        $storefrontId: Int
        $sellerId: String!
        $priceBookName: String
    ) {
        viewer {
            ...StorefrontRefetch_viewer
        }
    }
`;
