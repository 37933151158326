import * as tracking from 'dibs-tracking';

export type CallSellerModalLocation =
    | 'pdp'
    | 'storefront'
    | 'mc'
    | 'mcEmail'
    | 'sellerProfile'
    | 'conversationBody';

const trackingCategory = 'contact dealer';
const locationLabels: Record<CallSellerModalLocation, string> = {
    pdp: 'more from dealer',
    storefront: 'dealer storefront',
    mc: 'message center',
    sellerProfile: 'message center - seller profile',
    conversationBody: 'message center - conversation body',
    mcEmail: 'message center - email',
};

export const onCallModalOpen = (location: CallSellerModalLocation): void => {
    const label = locationLabels[location];
    tracking.trackEvent(
        {
            category: trackingCategory,
            action: 'call dealer clicked',
            label,
            isInteractiveEvent: true,
            eventName: tracking.eventNameConstants.EVENT_CONTACT_DEALER,
            step_interaction_name: tracking.stepInteractionConstants.STEP_INTERACTION_ENTRY,
            interaction_type: tracking.interactionTypeConstants.INTERACTION_TYPE_CALL_DEALER,
            trigger: label,
        },
        null
    );
};

export const onCallModalSuccess = (
    location: CallSellerModalLocation,
    allowDealerCallback?: boolean
): void => {
    const label = locationLabels[location];
    tracking.trackEvent(
        {
            category: trackingCategory,
            action: 'call dealer initiated',
            label,
            value: allowDealerCallback ? 1 : 0,
            eventName: tracking.eventNameConstants.EVENT_CONTACT_DEALER,
            step_interaction_name: tracking.stepInteractionConstants.STEP_INTERACTION_COMPLETE,
            interaction_type: tracking.interactionTypeConstants.INTERACTION_TYPE_CALL_DEALER,
            trigger: label,
        },
        null
    );
};
