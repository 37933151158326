// Flow types automatically removed

import root from './windowCheck';
export function getInitialSettings(props) {
    return {
        disableSticky: props.disableSticky === true,
        fullStateSize: props.fullStateSize || 0,
        stickyStateSize: props.stickyStateSize || 0,
        stickyWidthThreshold: props.stickyWidthThreshold || Infinity,
        stickyHeightThreshold: props.stickyHeightThreshold || Infinity,
    };
}
export function didSettingsChange(prevProps, nextProps) {
    return (
        nextProps !== prevProps &&
        (prevProps.disableSticky !== nextProps.disableSticky ||
            prevProps.fullStateSize !== nextProps.fullStateSize ||
            prevProps.stickyStateSize !== nextProps.stickyStateSize ||
            prevProps.stickyWidthThreshold !== nextProps.stickyWidthThreshold ||
            prevProps.stickyHeightThreshold !== nextProps.stickyHeightThreshold)
    );
}
export function getWindowWidth() {
    return root.innerWidth || 0;
}
export function getWindowScrollPosition() {
    return root.scrollY || root.pageYOffset || 0;
}
export function getElementHeight(el) {
    return el ? el.offsetHeight : 0;
}
export function getElementPosition(el) {
    if (el) {
        const winScrollY = getWindowScrollPosition();
        const relativePos = el.getBoundingClientRect();
        return winScrollY + relativePos.top;
    }

    return Infinity;
}
