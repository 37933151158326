import { Component } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { getBuyerId, getUserType, getPriceBookName } from 'dibs-cookie-jar';
import storefrontRoot from './storefrontRoot';
import StorefrontLayout from './StorefrontLayout';
import { addLoginHandlers } from 'dibs-buyer-layout/src/utils/loginHandlers';

class StorefrontRefetch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            isTrade: props.initialVariables.isTrade,
        };
        this.updateUserId = this.updateUserId.bind(this);
    }
    componentDidMount() {
        const userId = getBuyerId(document.cookie);
        if (userId) {
            this.updateUserId();
        }
        addLoginHandlers(this.updateUserId);
    }
    updateUserId() {
        const userId = getBuyerId(document.cookie);
        const isTrade = getUserType(document.cookie) === 'trade';
        const priceBookName = getPriceBookName(document.cookie) || 'DEFAULT';
        this.props.relay.refetch(
            {
                ...this.props.initialVariables,
                isTrade,
                userId,
                userIds: userId ? [userId] : [],
                hasUserId: !!userId,
                priceBookName,
            },
            null,
            () => {
                this.setState({ userId, isTrade });
            }
        );
    }
    render() {
        const { viewer, sellerData, isPreview } = this.props;
        return (
            <StorefrontLayout
                isTrade={this.state.isTrade}
                isPreview={isPreview}
                sellerData={sellerData}
                viewer={viewer}
            />
        );
    }
}

StorefrontRefetch.propTypes = {
    relay: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    sellerData: PropTypes.object.isRequired,
    isPreview: PropTypes.bool.isRequired,
    initialVariables: PropTypes.object.isRequired,
};

export default createRefetchContainer(
    StorefrontRefetch,
    {
        viewer: graphql`
            fragment StorefrontRefetch_viewer on Viewer {
                ...StorefrontLayout_viewer
            }
        `,
    },
    storefrontRoot
);
