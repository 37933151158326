/**
 * @generated SignedSource<<1120cc30e3161c7d495ed2914d976501>>
 * @relayHash 9eccba90e32379926eecfe99b871f4d2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/491.0.0-2024-12-11T17:51:15.109Z/storefrontRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type storefrontRootQuery$variables = {
  hasUserId: boolean;
  isTrade: boolean;
  priceBookName?: string | null;
  sellerId: string;
  storefrontId?: number | null;
  urlLabel: string;
  userId?: string | null;
  userIds?: ReadonlyArray<string | null> | null;
};
export type storefrontRootQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"StorefrontRefetch_viewer">;
  };
};
export type storefrontRootQuery = {
  response: storefrontRootQuery$data;
  variables: storefrontRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "storefrontId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "urlLabel"
},
v6 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIds"
},
v8 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "kind": "Variable",
  "name": "sellerId",
  "variableName": "sellerId"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v19 = [
  (v18/*: any*/),
  (v11/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v23 = [
  (v18/*: any*/),
  (v21/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPhotoVersion",
  "kind": "LinkedField",
  "name": "versions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webPath",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "storefrontRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StorefrontRefetch_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "storefrontRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canShowFolder",
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "viewerUser",
                "args": (v9/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "preventEmailFallback",
                            "value": true
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": "displayName(preventEmailFallback:true)"
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "Phone",
                "kind": "LinkedField",
                "name": "primaryPhone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "countryCodeNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "countryCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formattedPhoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": "isActive",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "status",
                        "value": "ACTIVE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasStatus",
                    "storageKey": "hasStatus(status:\"ACTIVE\")"
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "userPortfolios",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "HEART"
                    ]
                  },
                  {
                    "kind": "Variable",
                    "name": "userIds",
                    "variableName": "userIds"
                  }
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": [
              (v13/*: any*/)
            ],
            "concreteType": "Seller",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "condition": "hasUserId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "userIds",
                        "variableName": "userId"
                      }
                    ],
                    "concreteType": "Favorite",
                    "kind": "LinkedField",
                    "name": "favoritesByUser",
                    "plural": true,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  }
                ]
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SellerProfile",
                "kind": "LinkedField",
                "name": "sellerProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "company",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "storefrontId",
                    "variableName": "storefrontId"
                  }
                ],
                "concreteType": "StorefrontProfile",
                "kind": "LinkedField",
                "name": "storefrontProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aboutUs",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Photo",
                    "kind": "LinkedField",
                    "name": "photos",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhotoType",
                        "kind": "LinkedField",
                        "name": "type",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Photo",
                    "kind": "LinkedField",
                    "name": "logo",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "establishmentYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MediaAlbum",
                    "kind": "LinkedField",
                    "name": "galleryAlbum",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MediaElement",
                        "kind": "LinkedField",
                        "name": "mediaList",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sortOrder",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "element",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v10/*: any*/),
                                  (v14/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/)
                                ],
                                "type": "Photo",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  (v16/*: any*/),
                                  (v17/*: any*/)
                                ],
                                "type": "Video",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v11/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "shippingAddress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayCityStateCountry",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "line1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "line2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayCityStateZip",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Phone",
                "kind": "LinkedField",
                "name": "trackedPhone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDistinguished",
                "storageKey": null
              },
              {
                "alias": "isRewardsTier2",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "listName",
                    "value": "SELLER_REWARDS_2"
                  }
                ],
                "kind": "ScalarField",
                "name": "isAnchorListMember",
                "storageKey": "isAnchorListMember(listName:\"SELLER_REWARDS_2\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SellerOrderFeedbackPdpQueryConnection",
                "kind": "LinkedField",
                "name": "orderFeedbackPdp",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerOrderFeedbackPdpQueryMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AggregatedOrderFeedbackType",
                        "kind": "LinkedField",
                        "name": "aggregatedFeedback",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AggregatedOrderFeedbackQuestionType",
                            "kind": "LinkedField",
                            "name": "overallExperience",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mean",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SellerTiers",
                "kind": "LinkedField",
                "name": "sellerTiers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGoldTier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPlatinumTier",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SellerPreferences",
                "kind": "LinkedField",
                "name": "sellerPreferences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayStoreAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storeAddressByAppointmentOnly",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scrollLogoLg",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "urlLabel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeAssociation",
                "kind": "LinkedField",
                "name": "tradeAssociations",
                "plural": true,
                "selections": (v19/*: any*/),
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "storefrontData",
            "args": [
              {
                "kind": "Variable",
                "name": "dealer",
                "variableName": "urlLabel"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 12
              },
              (v13/*: any*/)
            ],
            "concreteType": "DealerStorefrontQueryConnection",
            "kind": "LinkedField",
            "name": "dealerStorefront",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DealerStorefrontQueryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categoryPath",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categoryCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ecommerceTrackingParams",
                        "storageKey": null
                      },
                      {
                        "condition": "hasUserId",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": "folderPortfolioItems",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "portfolioType",
                                "value": "FOLDER"
                              },
                              (v8/*: any*/)
                            ],
                            "concreteType": "PortfolioItemV2",
                            "kind": "LinkedField",
                            "name": "portfolioItems",
                            "plural": true,
                            "selections": [
                              (v15/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "heartPortfolioItems",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "portfolioType",
                                "value": "HEART"
                              },
                              (v8/*: any*/)
                            ],
                            "concreteType": "PortfolioItemV2",
                            "kind": "LinkedField",
                            "name": "portfolioItems",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "portfolioId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "portfolioItemId",
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ]
                      },
                      (v20/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "pdpMetaItems",
                        "kind": "LinkedField",
                        "name": "pdpMeta",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "topQuery",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "localizedPdpUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemClassification",
                        "kind": "LinkedField",
                        "name": "classification",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "creationDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemCreator",
                        "kind": "LinkedField",
                        "name": "creators",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "attribution",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Creator",
                            "kind": "LinkedField",
                            "name": "creator",
                            "plural": false,
                            "selections": (v19/*: any*/),
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArt",
                        "storageKey": null
                      },
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vertical",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentQuote",
                        "kind": "LinkedField",
                        "name": "shipmentQuotes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isComplimentary",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCalculated",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FinancialAmount",
                            "kind": "LinkedField",
                            "name": "totalAmount",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ConvertedAmounts",
                                "kind": "LinkedField",
                                "name": "convertedAmounts",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "USD",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShipmentServiceMethod",
                            "kind": "LinkedField",
                            "name": "serviceMethod",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Region",
                                "kind": "LinkedField",
                                "name": "region",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "regionName",
                                    "storageKey": null
                                  },
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isStorefrontOnly",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMultiSku",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isRingItem",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VariableAttribute",
                        "kind": "LinkedField",
                        "name": "variableAttributes",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "VariableAttributeSortType",
                            "kind": "LinkedField",
                            "name": "variableAttributeData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SwatchSort",
                                "kind": "LinkedField",
                                "name": "swatchSort",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Swatch",
                                    "kind": "LinkedField",
                                    "name": "swatch",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v18/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "imageUrl",
                                        "storageKey": null
                                      },
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Variable",
                            "name": "isTrade",
                            "variableName": "isTrade"
                          },
                          {
                            "kind": "Literal",
                            "name": "page",
                            "value": "searchAndBrowse"
                          },
                          {
                            "kind": "Variable",
                            "name": "priceBookName",
                            "variableName": "priceBookName"
                          }
                        ],
                        "concreteType": "ItemDisplayPriceType",
                        "kind": "LinkedField",
                        "name": "displayPrice",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ConvertedAmountList",
                            "kind": "LinkedField",
                            "name": "convertedAmountList",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currency",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "quantityDisplay",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentageOff",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "textType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showPriceLabel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showPriceVariability",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "QuickViewDisplayType",
                        "kind": "LinkedField",
                        "name": "quickViewDisplay",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          {
                            "alias": "mobileTitle",
                            "args": (v22/*: any*/),
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": "title(formatType:\"MOBILE\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "QuickViewParagraph",
                            "kind": "LinkedField",
                            "name": "paragraphs",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "key",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              {
                                "alias": "mobileText",
                                "args": (v22/*: any*/),
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": "text(formatType:\"MOBILE\")"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "QuickViewCreator",
                            "kind": "LinkedField",
                            "name": "creators",
                            "plural": true,
                            "selections": (v23/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "QuickViewCategorySegments",
                            "kind": "LinkedField",
                            "name": "paragraphAttributeNames",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "period",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "origin",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "style",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "periodPrefix",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemMeasurement",
                        "kind": "LinkedField",
                        "name": "measurement",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "addLabel",
                                "value": true
                              },
                              {
                                "kind": "Literal",
                                "name": "fields",
                                "value": [
                                  "height",
                                  "width",
                                  "depth",
                                  "diameter",
                                  "length"
                                ]
                              },
                              {
                                "kind": "Literal",
                                "name": "separator",
                                "value": " "
                              }
                            ],
                            "concreteType": "ItemDisplayMeasurementType",
                            "kind": "LinkedField",
                            "name": "display",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unit",
                                "storageKey": null
                              },
                              (v24/*: any*/)
                            ],
                            "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSize",
                            "kind": "LinkedField",
                            "name": "size",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": "productPhotos",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "limit",
                            "value": 1
                          }
                        ],
                        "concreteType": "ItemPhoto",
                        "kind": "LinkedField",
                        "name": "photos",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placeholder",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "smallPath",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "masterOrZoomPath",
                            "storageKey": null
                          },
                          (v25/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": "photos(limit:1)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemPhoto",
                        "kind": "LinkedField",
                        "name": "photos",
                        "plural": true,
                        "selections": [
                          (v25/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorsUrls",
                "kind": "LinkedField",
                "name": "creatorsUrls",
                "plural": true,
                "selections": (v23/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/491.0.0-2024-12-11T17:51:15.109Z/storefrontRootQuery",
    "metadata": {},
    "name": "storefrontRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "551e3f4b164d3875f891f5d7c70ef461";

export default node;
