/**
 * @generated SignedSource<<41c3ad64c7b7212c6d4585d5ac1873ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontHome_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontAboutSection_seller" | "StorefrontFeaturedSection_seller" | "StorefrontMediaSection_seller">;
  readonly " $fragmentType": "StorefrontHome_seller";
};
export type StorefrontHome_seller$key = {
  readonly " $data"?: StorefrontHome_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontHome_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontHome_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontAboutSection_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontMediaSection_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontFeaturedSection_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "b16ca51935fb6950d6a70543ba12ca26";

export default node;
