import { getBuyerId } from 'dibs-cookie-jar';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';

export function storefrontEnsureLogin({ relayEnvironment, authOptions = {} }) {
    const userId = getBuyerId(document.cookie);
    if (userId) {
        return Promise.resolve({ userId });
    }
    return authModalLoader
        .show({
            flow: authModalLoader.constants.DEFAULT_FLOW,
            relayEnvironment,
            ...authOptions,
        })
        .then(res => {
            if (res.err) {
                throw res.err;
            }
            return { userId: res.userId };
        });
}
