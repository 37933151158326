import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import get from 'lodash.get';
import classNames from 'classnames';

import styles from './styles/StorefrontSocial.scss';

import StorefrontCallButton from './StorefrontCallButton';
import StorefrontContactButton from './StorefrontContactButton';
import StorefrontSocialShare from './StorefrontSocialShare';

function StorefrontSocial(props) {
    return (
        <div className={styles.social}>
            {/* Share */}
            <div className={styles.shareButton}>
                <div className={styles.socialShareWrapper}>
                    <StorefrontSocialShare disable={props.isPreview} seller={props.seller} />
                </div>
            </div>
            {/* Message */}
            <StorefrontContactButton
                className={classNames(styles.socialButton, styles.contact)}
                isPreview={props.isPreview}
                userId={get(props, 'user.serviceId')}
                seller={props.seller}
            />
            {/* Call */}
            {!!get(props, 'seller.trackedPhone.phoneNumber') && (
                <StorefrontCallButton
                    className={styles.socialButton}
                    userId={get(props, 'user.serviceId')}
                    viewer={props.viewer}
                    seller={props.seller}
                    followDealer={props.followDealer}
                />
            )}
        </div>
    );
}

StorefrontSocial.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    user: PropTypes.object,
    seller: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    followDealer: PropTypes.func.isRequired,
};

export default createFragmentContainer(StorefrontSocial, {
    seller: graphql`
        fragment StorefrontSocial_seller on Seller {
            ...StorefrontContactButton_seller
            ...StorefrontCallButton_seller
            ...StorefrontSocialShare_seller
            trackedPhone {
                phoneNumber
            }
        }
    `,
    user: graphql`
        fragment StorefrontSocial_user on User {
            serviceId
        }
    `,
    viewer: graphql`
        fragment StorefrontSocial_viewer on Viewer {
            ...StorefrontCallButton_viewer
        }
    `,
});
