/**
 * @generated SignedSource<<fe3a0bfd31027cd93a15d0fe4ba34e67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontCallButton_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CallDealerModal_viewer">;
  readonly " $fragmentType": "StorefrontCallButton_viewer";
};
export type StorefrontCallButton_viewer$key = {
  readonly " $data"?: StorefrontCallButton_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontCallButton_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontCallButton_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CallDealerModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c67b43dc7930e02a0641e35b3ae5393a";

export default node;
