export const BASE_SHOP_ROUTE = 'shop/';

export const HEADSHOT_LARGE = 160;
export const HEADSHOT_SMALL = 120;
export const HEADSHOT_MOBILE_BREAKPOINT = 1024;

export const ABOUT_CHAR_LIMIT = 580;
export const ABOUT_CHAR_LIMIT_MOBILE = 350;

export const DEALER_STOREFRONT_LOGO = 'DEALER_STOREFRONT_LOGO';
export const DEALER_STOREFRONT_BANNER = 'DEALER_STOREFRONT_BANNER';
export const DEALER_STOREFRONT_HEADSHOT = 'DEALER_STOREFRONT_HEADSHOT';

export const MAX_ASSOCIATIONS = 2;
