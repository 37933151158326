/**
 * @generated SignedSource<<e2a2ab57c61858b329de11841d20d538>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontRefetch_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontLayout_viewer">;
  readonly " $fragmentType": "StorefrontRefetch_viewer";
};
export type StorefrontRefetch_viewer$key = {
  readonly " $data"?: StorefrontRefetch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontRefetch_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontRefetch_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontLayout_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "0af1ab8edca5d9d607f872d248fb31a4";

export default node;
