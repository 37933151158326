import { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import SwipeDetector from '../../../utils/touch/SwipeDetector';

import styles from './styles.scss';

class MobileCarouselWrapper extends Component {
    componentDidMount() {
        this.swipeDetector = new SwipeDetector({
            el: findDOMNode(this),
            onSwipeSuccess: direction =>
                direction.match({
                    left: this.props.onNext,
                    right: this.props.onPrevious,
                }),
        });
    }

    componentWillUnmount() {
        if (this.swipeDetector) {
            this.swipeDetector.destroy();
        }
    }

    render() {
        const className = classNames(styles.mediaCarousel, styles.swipable);

        return (
            <div className={className}>
                {/* Carousel */}
                {this.props.children}
            </div>
        );
    }
}

MobileCarouselWrapper.propTypes = {
    children: PropTypes.object.isRequired,

    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
};

export default MobileCarouselWrapper;
