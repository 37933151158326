/**
 * @generated SignedSource<<bb48595e1c2fdad9bb1118ccad463f51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontSocial_seller$data = {
  readonly trackedPhone: {
    readonly phoneNumber: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontCallButton_seller" | "StorefrontContactButton_seller" | "StorefrontSocialShare_seller">;
  readonly " $fragmentType": "StorefrontSocial_seller";
};
export type StorefrontSocial_seller$key = {
  readonly " $data"?: StorefrontSocial_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontSocial_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontSocial_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontContactButton_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontCallButton_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontSocialShare_seller"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Phone",
      "kind": "LinkedField",
      "name": "trackedPhone",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "37599e40e9e9137722e3543f09cb3b86";

export default node;
