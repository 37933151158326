'use strict';

const MultiTouchDetector = require('./MultiTouchDetector');
const { createEnum } = require('../../utils/enum');
const { getQuadrant, getPointAngle } = require('../math');

const noop = function () {};
/**
 * Enum class representing a swipe direction.
 */
const swipeDirectionEnum = createEnum('left', 'right', 'up', 'down');

const quadarantDirectionMap = [
    swipeDirectionEnum.right,
    swipeDirectionEnum.up,
    swipeDirectionEnum.left,
    swipeDirectionEnum.down,
];

/**
 * Utility to detect the direction of the swipe.
 * @param {Array} - Array consisting of the touch coordinates [x, y]
 * @return {Object} - Enum class
 */
function swipeDirection(distance) {
    const quadrant = getQuadrant(getPointAngle(distance[0], distance[1]) + 45);
    return quadarantDirectionMap[quadrant]();
}

/**
 * Utility class To handle swipe detection. Pass an element in and get notified on a swipe on that element. Instances
 * need to be destroyed when tracking is no longer needed.
 *
 * @param  {Object}  options.el Element to track.
 * @param  {Function}  [options.onPartialSwipe] Callback to fire on initial panning. Allows you to get distance panned.
 * @param  {Function}  [options.onSwipeSuccess] Callback to fire on successful swipe. Callback is passed the swipe direction.
 * @param  {Function}  [options.onSwipeFailure] Callback to fire on failed swipe.
 */
class SwipeDetector {
    constructor({ el, onPartialSwipe = noop, onSwipeSuccess = noop, onSwipeFailure = noop } = {}) {
        if (!el) {
            throw new Error('Must pass `el` to SwipeDetector');
        }

        Object.assign(this, {
            _distance: [null, null],
            _initialPosition: [null, null],
            _initialTime: null,
            _el: el,
            _onPartialSwipe: onPartialSwipe,
            _onSwipeSuccess: onSwipeSuccess,
            _onSwipeFailure: onSwipeFailure,
            _multiTouchDetector: new MultiTouchDetector({
                onSingleTouchStart: this._onSingleTouchStart.bind(this),
                onSingleTouchMove: this._onSingleTouchMove.bind(this),
                onSingleTouchEnd: this._onSingleTouchEnd.bind(this),
                el,
            }),
        });
    }

    _onSingleTouchStart(e) {
        // Get the initial position and time so we can do a difference on them later.
        this._initialPosition = [e.touches[0].clientX, e.touches[0].clientY];
        this._initialTime = Date.now();
    }

    _onSingleTouchMove(e) {
        this._distance = [
            e.touches[0].clientX - this._initialPosition[0],
            e.touches[0].clientY - this._initialPosition[1],
        ];

        const direction = swipeDirection(this._distance);

        /**
         * We don't wanna messup the scrolling experience so we only call preventDefault when
         * swiping horizontaly.
         */
        if (direction.isRight() || direction.isLeft()) {
            e.preventDefault();
        }

        this._onPartialSwipe(this._distance, direction);
    }

    _onSingleTouchEnd() {
        if (this._shouldRegisterSwipe()) {
            // Determine swipe direction based on sign of distance.
            this._onSwipeSuccess(swipeDirection(this._distance));
        } else {
            this._onSwipeFailure();
        }

        this._distance = [0, 0];
    }

    _shouldRegisterSwipe() {
        const totalTime = Date.now() - this._initialTime;
        let absoluteDistance;

        return this._distance.some(distance => {
            absoluteDistance = Math.abs(distance);

            // Register a swipe if:
            // slide duration is less than 250ms and if slide distance is greater than 20px
            // OR if slide distance is greater than half the width of the element.
            return (
                absoluteDistance > this._el.clientWidth / 2 ||
                (absoluteDistance > 20 && totalTime < 250)
            );
        });
    }

    destroy() {
        this._multiTouchDetector.destroy();
    }
}

module.exports = SwipeDetector;
