/* eslint-disable import/no-default-export */
import { createRoot } from 'react-dom/client';

export default function contactDealerLauncher({
    currency,
    environment,
    itemId,
    selectedSkuId,
    launchOrigin,
    modalType,
    sellerId,
    showAppInstallPrompt,
    userId,
    onSuccessfulShippingQuoteRequest,
    onSuccessfulContactSellerRequest,
    itemQuantity,
}) {
    const modalContainer = document.createElement('div');
    import(
        /* webpackChunkName: "contactDealerModal" */
        '../src/ContactDealerModal/contactDealerAsyncImports'
    ).then(mod => {
        const root = createRoot(modalContainer, { identifierPrefix: 'contactDealerModal' });
        const unmount = () => root.unmount();
        const { components, helpers } = mod;

        const rendererProps = {
            environment,
            itemId,
            selectedSkuId,
            launchOrigin,
            modalType,
            pageCurrency: currency,
            sellerId,
            showAppInstallPrompt,
            userId,
            onSuccessfulShippingQuoteRequest,
            onSuccessfulContactSellerRequest,
            unmount,
            itemQuantity,
        };

        const { ContactDealerModal, IntlProvider } = components;
        const { serverVars } = helpers;

        root.render(
            <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
                <ContactDealerModal {...rendererProps} />
            </IntlProvider>
        );
    });
}
