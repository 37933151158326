import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { shareViaFacebook, shareViaPinterest } from '../../utils/shareHelpers';

import PropTypes from 'prop-types';
import ShareIcon from 'dibs-icons/exports/legacy/Share';
import EnvelopeIcon from 'dibs-icons/exports/legacy/Envelope';
import FacebookIcon from 'dibs-icons/exports/legacy/Facebook';
import PinterestIcon from 'dibs-icons/exports/legacy/Pinterest';
import { Link } from 'dibs-elements/exports/Link';

import styles from './styles/StorefrontSocialShare.scss';
import classnames from 'classnames';

const ENVELOPE = 'envelope';
const FACEBOOK = 'facebook';
const PINTEREST = 'pinterest';

const ICONS = {};
ICONS[ENVELOPE] = EnvelopeIcon;
ICONS[FACEBOOK] = FacebookIcon;
ICONS[PINTEREST] = PinterestIcon;

const messages = defineMessages({
    subject: {
        id: 'StorefrontSocialShare.emailSubject',
        defaultMessage: '{company} on 1stDibs',
    },
    body: {
        id: 'StorefrontSocialShare.emailBody',
        defaultMessage: '"See more amazing pieces from {company} on 1stDibs. {url}',
    },
    text: {
        id: 'StorefrontSocialShare.text',
        defaultMessage: 'See more amazing pieces from {company} on 1stDibs.',
    },
});

/**
 * @class StorefrontSocialShare
 * Has 3 states
 *  - Closed
 *  - Open with icons showing
 *  - Open with Thank You message showing
 */

const StorefrontSocialShare = ({ disable, onOpenSocialShare, seller }) => {
    const intl = useIntl();
    const [state, setState] = useState({
        isOpen: false, // is the component open or closed
        showThanks: false, // show the thank you message
    });

    const closeComponent = useCallback(() => {
        if (state.isOpen) {
            setState({
                isOpen: false,
                showThanks: false,
            });
        }
    }, [state.isOpen]);

    const openComponent = useCallback(() => {
        if (!state.isOpen) {
            setState({
                isOpen: true,
                showThanks: false,
            });

            if (onOpenSocialShare) {
                onOpenSocialShare();
            }
        }
    }, [onOpenSocialShare, state.isOpen]);

    const showThanks = useCallback(() => {
        if (state.isOpen && !state.showThanks) {
            setState(s => ({ ...s, showThanks: true }));
        }
    }, [state.isOpen, state.showThanks]);

    const showThanksAndClose = useCallback(() => {
        showThanks();
        setTimeout(() => closeComponent(), 1000);
    }, [showThanks, closeComponent]);

    /**
     * Handler for when share icon is clicked
     *  - sets the state to open with icons showing
     *  - sets the state to closed
     */
    const onClickShare = useCallback(
        e => {
            e.preventDefault();
            if (!disable) {
                if (state.isOpen) {
                    closeComponent();
                } else {
                    openComponent();
                }
            }
        },
        [openComponent, closeComponent, state.isOpen, disable]
    );

    /**
     * Handler for when social icon is clicked
     */
    const onClickSocialIcon = useCallback(
        (socialIcon, e) => {
            e.preventDefault();
            const company = seller?.sellerProfile?.company;
            const url = window.location.href;
            const text = intl.formatMessage(messages.text, { company });

            switch (socialIcon) {
                case ENVELOPE: {
                    const subject = encodeURIComponent(
                        intl.formatMessage(messages.subject, { company })
                    );
                    const body = encodeURIComponent(
                        intl.formatMessage(messages.body, { company, url })
                    );
                    window.location.href = `mailto:?subject=${subject}&body=${body}`;
                    break;
                }
                case FACEBOOK:
                    shareViaFacebook();
                    break;
                case PINTEREST:
                    shareViaPinterest({ description: text, url });
                    break;
            }

            showThanksAndClose();
        },
        [intl, seller, showThanksAndClose]
    );

    useEffect(() => {
        window.addEventListener('scroll', closeComponent);

        return () => {
            window.removeEventListener('scroll', closeComponent);
        };
    }, [closeComponent]);

    const className = classnames(styles.container, {
        [styles.isOpen]: state.isOpen,
        [styles.showThanks]: state.showThanks,
    });

    const socialWrapperClass = classnames(styles.socialWrapper, 'rowFlex');
    const socialButtonClass = classnames(styles.button, styles.social, 'colXs4');
    const thankYouMessageClass = classnames(styles.thankYouMessage, 'colXs12');

    return (
        <div className={className}>
            <div className={styles.wrapper}>
                {/* Social Icons */}
                <div className={socialWrapperClass}>
                    {[ENVELOPE, FACEBOOK, PINTEREST].map(icon => {
                        const Icon = ICONS[icon] || null;
                        return (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid, react/forbid-elements
                            <a
                                href="#"
                                key={icon}
                                className={socialButtonClass}
                                onClick={e => onClickSocialIcon(icon, e)}
                            >
                                <Icon className={styles.socialSvg} />
                            </a>
                        );
                    })}
                    <div className={thankYouMessageClass}>
                        <FormattedMessage
                            id="StorefrontSocialShare.thankYou"
                            defaultMessage="THANKS!"
                        />
                    </div>
                </div>
                {/* Share Icon */}
                <Link className={`${styles.button} ${styles.share}`} onClick={onClickShare}>
                    <ShareIcon />
                </Link>
            </div>
        </div>
    );
};

StorefrontSocialShare.propTypes = {
    onOpenSocialShare: PropTypes.func,
    disable: PropTypes.bool,
    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontSocialShare, {
    seller: graphql`
        fragment StorefrontSocialShare_seller on Seller {
            sellerProfile {
                company
            }
        }
    `,
});
