'use strict';

const noop = function () {};

// Type of touch.
// NOTE: Currently doesn't distinguish touch types greater than 2. If needed we can add TRIPE, QUADRUPLE, etc.
const TOUCH_TYPE_SINGLE = 'single';
const TOUCH_TYPE_DOUBLE = 'double';
const TOUCH_TYPE_MANY = 'many';

/**
 * Utility class To handle multi-touch detection. Pass an element in and it allow you to attach handlers for multi-touch.
 * Instances need to be destroyed when tracking is no longer needed.
 *
 * @param  {Object}  options.el Element to track.
 * @param  {Function}  [options.onSingleTouchStart] Callback to fire on single touch start.
 * @param  {Function}  [options.onSingleTouchMove] Callback to fire on single touch move.
 * @param  {Function}  [options.onSingleTouchEnd] Callback to fire on single touch end.
 * @param  {Function}  [options.onDoubleTouchStart] Callback to fire on double touch start.
 * @param  {Function}  [options.onDoubleTouchMove] Callback to fire on double touch move.
 * @param  {Function}  [options.onDoubleTouchEnd] Callback to fire on double touch end.
 * @param  {Function}  [options.onManyTouchStart] Callback to fire on many touch start.
 * @param  {Function}  [options.onManyTouchMove] Callback to fire on many touch move.
 * @param  {Function}  [options.onManyTouchEnd] Callback to fire on many touch end.
 */
class MultiTouchDetector {
    constructor({
        el,
        onSingleTouchStart = noop,
        onSingleTouchMove = noop,
        onSingleTouchEnd = noop,
        onDoubleTouchStart = noop,
        onDoubleTouchMove = noop,
        onDoubleTouchEnd = noop,
        onManyTouchStart = noop,
        onManyTouchMove = noop,
        onManyTouchEnd = noop,
    } = {}) {
        if (!el) {
            throw new Error('Must pass `el` to MultiTouchDetector');
        }

        Object.assign(this, {
            _type: null,
            _el: el,
            _onSingleTouchStart: onSingleTouchStart,
            _onSingleTouchMove: onSingleTouchMove,
            _onSingleTouchEnd: onSingleTouchEnd,
            _onDoubleTouchStart: onDoubleTouchStart,
            _onDoubleTouchMove: onDoubleTouchMove,
            _onDoubleTouchEnd: onDoubleTouchEnd,
            _onManyTouchStart: onManyTouchStart,
            _onManyTouchMove: onManyTouchMove,
            _onManyTouchEnd: onManyTouchEnd,
            _onTouchStart: this._onTouchStart.bind(this),
            _onTouchMove: this._onTouchMove.bind(this),
            _onTouchEnd: this._onTouchEnd.bind(this),
        });

        this._el.addEventListener('touchstart', this._onTouchStart);
        this._el.addEventListener('touchmove', this._onTouchMove);
        this._el.addEventListener('touchend', this._onTouchEnd);
    }

    destroy() {
        this._el.removeEventListener('touchstart', this._onTouchStart);
        this._el.removeEventListener('touchmove', this._onTouchMove);
        this._el.removeEventListener('touchend', this._onTouchEnd);
    }

    _onTouchStart(e) {
        const { touches } = e;

        // If we've gone to many touch...
        if (touches.length > 2) {
            // if we were on double, end it.
            this._conditionalEndDouble(e);

            // if we were on single, end it.
            this._conditionalEndSingle(e);

            // if we're not already on many, go to many.
            this._conditionalStartMany(e);
            return;
        }

        // If we've gone to double...
        if (touches.length === 2) {
            // if we were on single, end it.
            this._conditionalEndSingle(e);

            // go to double.
            this._conditionalStartDouble(e);
            return;
        }

        // If we've gone to single...
        // go go to single.
        this._conditionalStartSingle(e);
    }

    _onTouchMove(e) {
        if (this.isMany()) {
            this._onManyTouchMove(e);
            return;
        }

        if (this.isDouble()) {
            this._onDoubleTouchMove(e);
            return;
        }

        if (this.isSingle()) {
            this._onSingleTouchMove(e);
        }
    }

    _onTouchEnd(e) {
        const { touches } = e;

        // If we're still on many touch, we'll just ignore the change.
        if (touches.length > 2) {
            return;
        }

        // If we've gone down to only 2 touches...
        if (touches.length === 2) {
            // if we were on many, end it.
            this._conditionalEndMany(e);

            // restart double.
            this._conditionalStartDouble(e);
            return;
        }

        // If we've gone down to 1 touch...
        if (touches.length === 1) {
            // if we were on many, end it.
            this._conditionalEndMany(e);

            // if we were on double, end it.
            this._conditionalEndDouble(e);

            // restart single.
            this._conditionalStartSingle(e);
            return;
        }

        // If we've removed all touches...
        // if we were on many, end it.
        this._conditionalEndMany(e);

        // if we were on double, end it.
        this._conditionalEndDouble(e);

        // if we were on single, end it.
        this._conditionalEndSingle(e);
        this._type = null;
    }

    isSingle() {
        return this._type === TOUCH_TYPE_SINGLE;
    }

    isDouble() {
        return this._type === TOUCH_TYPE_DOUBLE;
    }

    isMany() {
        return this._type === TOUCH_TYPE_MANY;
    }

    _conditionalEndSingle(e) {
        if (this.isSingle()) {
            this._onSingleTouchEnd(e);
        }
    }

    _conditionalEndDouble(e) {
        if (this.isDouble()) {
            this._onDoubleTouchEnd(e);
        }
    }

    _conditionalEndMany(e) {
        if (this.isMany()) {
            this._onManyTouchEnd(e);
        }
    }

    _conditionalStartSingle(e) {
        if (!this.isSingle()) {
            this._type = TOUCH_TYPE_SINGLE;
            this._onSingleTouchStart(e);
        }
    }

    _conditionalStartDouble(e) {
        if (!this.isDouble()) {
            this._type = TOUCH_TYPE_DOUBLE;
            this._onDoubleTouchStart(e);
        }
    }

    _conditionalStartMany(e) {
        if (!this.isMany()) {
            this._type = TOUCH_TYPE_MANY;
            this._onManyTouchStart(e);
        }
    }
}

module.exports = MultiTouchDetector;
